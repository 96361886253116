import company from "./company";
// import store from "./store";
import goods from "./goods";
// import trading from "./trading";
import customer from "./customer";
// import promoteCenter from "./promoteCenter";
import setting from "./setting";
// import simpleRouters from "./simpleRouters";

export default [ //标准版路由
  company,
  // store,
  goods,
  // trading,
  customer,
  // promoteCenter,
  setting,
  // ...simpleRouters
];