<template>
  <div class="header_box">
    <div class="version flex justify-start items-center">
      <span>{{ companyName}}</span>
      <!-- <el-button type="text" style="margin-left:20px;" @click="switchFn"
        >切换公司</el-button
      > -->
    </div>
    <HeaderUserInfo @onVersionSet="versionSetFn" />
    <el-dialog
      title="版本设置"
      :visible.sync="switchDialog"
      width="30%"
      @close="onClose"
    >
      <div>
        <span style="margin-right:20px;">选择版本</span>
        <el-radio-group v-model="version" size="small">
          <el-radio label="标准版" border>标准版</el-radio>
          <el-radio label="完整版" border>完整版</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onClose">取 消</el-button>
        <el-button size="small" type="primary" @click="onSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HeaderUserInfo from "./HeaderUserInfo";
import { mapState } from "vuex";
export default {
  name: "HeaderNav",
  components: {
    HeaderUserInfo,
  },
  data() {
    return {
      activeIndex: "",
      menuList: [],
      switchDialog: false,
      versionName: "完整版",
      version: "",
      isCollapse: false,
    };
  },
  watch: {
    isCollapse: {
      handler: function(newVal) {
        this.$emit("isCollapseChange", newVal);
      },
    },
  },
  created() {
    this.initMenu();
    this.activeIndex = "/" + this.$route.path.split("/")[1];
  },
  computed:{
    ...mapState({
      companyName: (state) => state.profile.companyName,
    }),
  },
  methods: {
    isCollapseChange() {
      this.isCollapse = !this.isCollapse;
    },
    initMenu() {
      this.menuList = [];
      let list = this.$router.options.routes;
      for (let k of list) {
        if (k.path != "/" && k.path != "/login") {
          this.menuList.push(k);
        }
      }
    },
    handleSelect(val) {
      this.$router.push(val);
    },
    versionSetFn() {
      this.switchFn();
    },
    switchFn() {
      this.switchDialog = true;
      this.version = this.versionName;
    },
    onClose() {
      this.switchDialog = false;
    },
    onSure() {
      this.versionName = this.version;
      this.switchDialog = false;
      this.$store.commit("setVersion", this.version);
    },
  },
};
</script>

<style lang="less" scoped>
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 14px;

  a {
    text-decoration: none;
  }
  .el-menu-item {
    background: #fff !important;
  }
}
</style>
