//提示音
const local = process.env.NODE_ENV === "development";
const host = local
    ? "http://" + window.location.host
    : "https://" + window.location.host;

export function audioPlay() {
    // 提示音
    const audio = new Audio(host + "/chat_msg_tip.mp3");
    return audio.play();
}
export function audioReceiveEnquiryPlay() {
    // 收到询价提示音
    const audio = new Audio(host + "/receiveEnquiry.mp3");
    return audio.play();
}
export function audioOfferPlay() {
    // 报价提示音
    const audio = new Audio(host + "/offer.mp3");
    return audio.play();
}
export function audioWinOfferPlay() {
    // 报价已中标提示音
    const audio = new Audio(host + "/winOffer.mp3");
    return audio.play();
}
